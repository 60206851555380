


@import "fonts";
@import "light-variables";
@import "base-variables";
@import "bulma-import";
@import "fontawesome-import";
@import "academicons-import";
@import "base";
@import "light-style";

@media (prefers-color-scheme: dark) {
    @import "fonts";
    @import "dark-variables";
    @import "base-variables";
    @import "bulma-import";
    @import "fontawesome-import";
    @import "academicons-import";
    @import "base";
    @import "dark-style"; }


