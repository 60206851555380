.modal-card-title {
    color: $primary; }

.modal-card-body {
    background-color: $background; }

.markdown {
    strong {
        color: $body-color; } }

.modal-background {
    background-color: rgba(0,0,0,0.8); }

.modal-close {
    background-color: $primary; }
